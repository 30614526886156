import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/services/authServices';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'language-new',
    pathMatch: 'full'
  },
  {
    path: 'home',
    //loadChildren: './home/home.module#HomePageModule'
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'list',
  //   loadChildren: './list/list.module#ListPageModule'
  // },
  {
    path: 'login',
    //loadChildren: './login/login.module#LoginPageModule'
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'brand-list',
    //loadChildren: './brand-list/brand-list.module#BrandListPageModule'
    loadChildren: () => import('./brand-list/brand-list.module').then(m => m.BrandListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'no-internet-connection',
    //loadChildren: './no-internet-connection/no-internet-connection.module#'
    loadChildren: () => import('./no-internet-connection/no-internet-connection.module').then(m => m.NoInternetConnectionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'filter',
    // loadChildren: './filter/filter.module#FilterPageModule'
    loadChildren: () => import('./filter/filter.module').then(m => m.FilterPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'search',
    // loadChildren: './search/search.module#SearchPageModule' 
    loadChildren: () => import('./search/search.module').then(m => m.SearchPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'scheme',
    // loadChildren: './scheme/scheme.module#SchemePageModule'
    loadChildren: () => import('./scheme/scheme.module').then(m => m.SchemePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'scheme-detail',
    // loadChildren: './scheme-detail/scheme-detail.module#SchemeDetailPageModule'
    loadChildren: () => import('./scheme-detail/scheme-detail.module').then(m => m.SchemeDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product-list',
    //loadChildren: './product-list/product-list.module#ProductListPageModule'
    loadChildren: () => import('./product-list/product-list.module').then(m => m.ProductListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product-detail',
    // loadChildren: './product-detail/product-detail.module#ProductDetailPageModule' 
    loadChildren: () => import('./product-detail/product-detail.module').then(m => m.ProductDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'query',
    //loadChildren: './query/query.module#QueryPageModule'
    loadChildren: () => import('./query/query.module').then(m => m.QueryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'query-add',
    //loadChildren: './query-add/query-add.module#QueryAddPageModule'
    loadChildren: () => import('./query-add/query-add.module').then(m => m.QueryAddPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'query-detail',
    // loadChildren: './query-detail/query-detail.module#QueryDetailPageModule'
    loadChildren: () => import('./query-detail/query-detail.module').then(m => m.QueryDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    // loadChildren: './profile/profile.module#ProfilePageModule'
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'intro-slides',
    //loadChildren: './intro-slider/intro-slider.module#IntroSliderPageModule'
    loadChildren: () => import('./intro-slider/intro-slider.module').then(m => m.IntroSliderPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile-edit',
    // loadChildren: './profile-edit/profile-edit.module#ProfileEditPageModule'
    loadChildren: () => import('./profile-edit/profile-edit.module').then(m => m.ProfileEditPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'language',
    //loadChildren: './language/language.module#LanguagePageModule'
    loadChildren: () => import('./language/language.module').then(m => m.LanguagePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'rate-visit',
    // loadChildren: './rate-visit/rate-visit.module#RateVisitPageModule'
    loadChildren: () => import('./rate-visit/rate-visit.module').then(m => m.RateVisitPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'terms', //loadChildren: './terms/terms.module#TermsPageModule' 
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsPageModule),
    canActivate: [AuthGuard]
  },
  // { path: 'comingsoon', loadChildren: './comingsoon/comingsoon.module#ComingsoonPageModule' },
  {
    path: 'sale-capture', //loadChildren: './sale-capture/sale-capture.module#SaleCapturePageModule'
    loadChildren: () => import('./sale-capture/sale-capture.module').then(m => m.SaleCapturePageModule),
    canActivate: [AuthGuard]

  },
  {
    path: 'sale-capture-add',// loadChildren: './sale-capture-add/sale-capture-add.module#SaleCaptureAddPageModule'
    loadChildren: () => import('./sale-capture-add/sale-capture-add.module').then(m => m.SaleCaptureAddPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sale-capture-detail',// loadChildren: './sale-capture-detail/sale-capture-detail.module#SaleCaptureDetailPageModule'
    loadChildren: () => import('./sale-capture-detail/sale-capture-detail.module').then(m => m.SaleCaptureDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'whats-new',//loadChildren: './whats-new/whats-new.module#WhatsNewPageModule'
    loadChildren: () => import('./whats-new/whats-new.module').then(m => m.WhatsNewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'whats-new-detail',// loadChildren: './whats-new-detail/whats-new-detail.module#WhatsNewDetailPageModule'
    loadChildren: () => import('./whats-new-detail/whats-new-detail.module').then(m => m.WhatsNewDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'bid-detail', //loadChildren: './bid-detail/bid-detail.module#BidDetailPageModule' 
    loadChildren: () => import('./bid-detail/bid-detail.module').then(m => m.BidDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'bid-list',// loadChildren: './bid-list/bid-list.module#BidListPageModule' 
    loadChildren: () => import('./bid-list/bid-list.module').then(m => m.BidListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'category-list',// loadChildren: './category-list/category-list.module#CategoryListPageModule'
    loadChildren: () => import('./category-list/category-list.module').then(m => m.CategoryListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'category-filter',// loadChildren: './category-filter/category-filter.module#CategoryFilterPageModule'
    loadChildren: () => import('./category-filter/category-filter.module').then(m => m.CategoryFilterPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'category-detail',// loadChildren: './category-detail/category-detail.module#CategoryDetailPageModule'
    loadChildren: () => import('./category-detail/category-detail.module').then(m => m.CategoryDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'warranty-detail',// loadChildren: './warranty-detail/warranty-detail.module#WarrantyDetailPageModule'
    loadChildren: () => import('./warranty-detail/warranty-detail.module').then(m => m.WarrantyDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'accessory-list',// loadChildren: './accessory-list/accessory-list.module#AccessoryListPageModule' 
    loadChildren: () => import('./accessory-list/accessory-list.module').then(m => m.AccessoryListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'accessory-detail',// loadChildren: './accessory-detail/accessory-detail.module#AccessoryDetailPageModule' 
    loadChildren: () => import('./accessory-detail/accessory-detail.module').then(m => m.AccessoryDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reward-products',// loadChildren: './reward-products/reward-products.module#RewardProductsPageModule'
    loadChildren: () => import('./reward-products/reward-products.module').then(m => m.RewardProductsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reward-catalogue',// loadChildren: './reward-catalogue/reward-catalogue.module#RewardCataloguePageModule' 
    loadChildren: () => import('./reward-catalogue/reward-catalogue.module').then(m => m.RewardCataloguePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reward-catalogue-list', //loadChildren: './reward-catalogue-list/reward-catalogue-list.module#RewardCatalogueListPageModule'
    loadChildren: () => import('./reward-catalogue-list/reward-catalogue-list.module').then(m => m.RewardCatalogueListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reward-brand', //loadChildren: './reward-brand/reward-brand.module#RewardBrandPageModule' 
    loadChildren: () => import('./reward-brand/reward-brand.module').then(m => m.RewardBrandPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'certificate', //loadChildren: './certificate/certificate.module#CertificatePageModule'
    loadChildren: () => import('./certificate/certificate.module').then(m => m.CertificatePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'videos', //loadChildren: './videos/videos.module#VideosPageModule'
    loadChildren: () => import('./video-list/video-list.module').then( m => m.VideoListPageModule),
    canActivate: [AuthGuard]
  },
  // { path: 'escalation-matrix', loadChildren: './escalation-matrix/escalation-matrix.module#EscalationMatrixPageModule' },
  {
    path: 'contact-matrix',// loadChildren: './contact-matrix/contact-matrix.module#ContactMatrixPageModule' 
    loadChildren: () => import('./contact-matrix/contact-matrix.module').then(m => m.ContactMatrixPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'catalouge-detail', //loadChildren: './catalouge-detail/catalouge-detail.module#CatalougeDetailPageModule'
    loadChildren: () => import('./catalouge-detail/catalouge-detail.module').then(m => m.CatalougeDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'voucher-listing', //loadChildren: './voucher-listing/voucher-listing.module#VoucherListingPageModule' 
    loadChildren: () => import('./voucher-listing/voucher-listing.module').then(m => m.VoucherListingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'filter-voucher', //loadChildren: './filter-voucher/filter-voucher.module#FilterVoucherPageModule' 
    loadChildren: () => import('./filter-voucher/filter-voucher.module').then(m => m.FilterVoucherPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'voucher-listing-detail',// loadChildren: './voucher-listing-detail/voucher-listing-detail.module#VoucherListingDetailPageModule'
    loadChildren: () => import('./voucher-listing-detail/voucher-listing-detail.module').then(m => m.VoucherListingDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'redemption-history', //loadChildren: './redemption-history/redemption-history.module#RedemptionHistoryPageModule' 
    loadChildren: () => import('./redemption-history/redemption-history.module').then(m => m.RedemptionHistoryPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product-request',// loadChildren: './product-request/product-request.module#ProductRequestPageModule'
    loadChildren: () => import('./product-request/product-request.module').then(m => m.ProductRequestPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product-request-detail',// loadChildren: './product-request-detail/product-request-detail.module#ProductRequestDetailPageModule' 
    loadChildren: () => import('./product-request-detail/product-request-detail.module').then(m => m.ProductRequestDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'launch-live',// loadChildren: './launch-live/launch-live.module#LaunchLivePageModule'
    loadChildren: () => import('./launch-live/launch-live.module').then(m => m.LaunchLivePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-management',// loadChildren: './user-management/user-management.module#UserManagementPageModule' 
    loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-management-add',// loadChildren: './user-management-add/user-management-add.module#UserManagementAddPageModule' 
    loadChildren: () => import('./user-management-add/user-management-add.module').then(m => m.UserManagementAddPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile-edit-unverified', //loadChildren: './profile-edit-unverified/profile-edit-unverified.module#ProfileEditUnverifiedPageModule' 
    loadChildren: () => import('./profile-edit-unverified/profile-edit-unverified.module').then(m => m.ProfileEditUnverifiedPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'faq', //loadChildren: './faq/faq.module#FaqPageModule' 
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'query-add-t2',// loadChildren: './query-add-t2/query-add-t2.module#QueryAddT2PageModule'
    loadChildren: () => import('./query-add-t2/query-add-t2.module').then(m => m.QueryAddT2PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'query-detail-t2',// loadChildren: './query-detail-t2/query-detail-t2.module#QueryDetailT2PageModule' 
    loadChildren: () => import('./query-detail-t2/query-detail-t2.module').then(m => m.QueryDetailT2PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-management-t2',// loadChildren: './user-management-t2/user-management-t2.module#UserManagementT2PageModule'
    loadChildren: () => import('./user-management-t2/user-management-t2.module').then(m => m.UserManagementT2PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-management-add-t2', //loadChildren: './user-management-add-t2/user-management-add-t2.module#UserManagementAddT2PageModule' 
    loadChildren: () => import('./user-management-add-t2/user-management-add-t2.module').then(m => m.UserManagementAddT2PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sales-capture-t2', //loadChildren: './sales-capture-t2/sales-capture-t2.module#SalesCaptureT2PageModule' 
    loadChildren: () => import('./sales-capture-t2/sales-capture-t2.module').then(m => m.SalesCaptureT2PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'performance-chart',// loadChildren: './performance-chart/performance-chart.module#PerformanceChartPageModule'
    loadChildren: () => import('./performance-chart/performance-chart.module').then(m => m.PerformanceChartPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 't2-performance-filter',// loadChildren: './t2-performance-filter/t2-performance-filter.module#T2PerformanceFilterPageModule' 
    loadChildren: () => import('./t2-performance-filter/t2-performance-filter.module').then(m => m.T2PerformanceFilterPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sales-capture-t2',// loadChildren: './sales-capture-t2/sales-capture-t2.module#SalesCaptureT2PageModule'
    loadChildren: () => import('./sales-capture-t2/sales-capture-t2.module').then(m => m.SalesCaptureT2PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sales-capture-t2-detail',// loadChildren: './sales-capture-t2-detail/sales-capture-t2-detail.module#SalesCaptureT2DetailPageModule'
    loadChildren: () => import('./sales-capture-t2-detail/sales-capture-t2-detail.module').then(m => m.SalesCaptureT2DetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'activate-role',// loadChildren: './activate-role/activate-role.module#ActivateRolePageModule' 
    loadChildren: () => import('./activate-role/activate-role.module').then(m => m.ActivateRolePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'performance-details-list',// loadChildren: './performance-details-list/performance-details-list.module#PerformanceDetailsListPageModule'
    loadChildren: () => import('./performance-details-list/performance-details-list.module').then(m => m.PerformanceDetailsListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'services', //loadChildren: './services/services.module#ServicesPageModule'
    loadChildren: () => import('./services/services.module').then(m => m.ServicesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'services-detail',// loadChildren: './services-detail/services-detail.module#ServicesDetailPageModule'
    loadChildren: () => import('./services-detail/services-detail.module').then(m => m.ServicesDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'performance-chart-t2', //loadChildren: './performance-chart-t2/performance-chart-t2.module#PerformanceChartT2PageModule' 
    loadChildren: () => import('./performance-chart-t2/performance-chart-t2.module').then(m => m.PerformanceChartT2PageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'performance-chart-t2-detail',// loadChildren: './performance-chart-t2-detail/performance-chart-t2-detail.module#PerformanceChartT2DetailPageModule'
    loadChildren: () => import('./performance-chart-t2-detail/performance-chart-t2-detail.module').then(m => m.PerformanceChartT2DetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'csr-activity', //loadChildren: './csr-activity/csr-activity.module#CsrActivityPageModule' 
    loadChildren: () => import('./csr-activity/csr-activity.module').then(m => m.CsrActivityPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'csr-activity-detail',// loadChildren: './csr-activity-detail/csr-activity-detail.module#CsrActivityDetailPageModule'
    loadChildren: () => import('./csr-activity-detail/csr-activity-detail.module').then(m => m.CsrActivityDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'language-new',
    loadChildren: () => import('./language-new/language-new.module').then(m => m.LanguageNewPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'brochure-download',
    loadChildren: () => import('./brochure-download/brochure-download.module').then(m => m.BrochureDownloadPageModule),
    canActivate: [AuthGuard]
    //  './brochure-download/brochure-download.module#BrochureDownloadPageModule' 
  },
  {
    path: 'username-login', loadChildren: () => import('./username-login/username-login.module').then(m => m.UsernameLoginPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'retailer-list', loadChildren: () => import('./retailer-list/retailer-list.module').then(m => m.RetailerListPageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'retailer-detail', loadChildren: () => import('./retailer-detail/retailer-detail.module').then(m => m.RetailerDetailPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'visit-add',
    loadChildren: () => import('./vistAso/visit-add/visit-add.module').then( m => m.VisitAddPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'retailer-detail',
    loadChildren: () => import('./retailer-details/retailer-detail/retailer-detail.module').then( m => m.RetailerDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'referral',
    loadChildren: () => import('./referral/referral.module').then( m => m.ReferralPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reward-point',
    loadChildren: () => import('./reward-point/reward-point.module').then( m => m.RewardPointPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile-srd',
    loadChildren: () => import('./profile-srd/profile-srd.module').then( m => m.ProfileSrdPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'invoice-upload',
    loadChildren: () => import('./invoice-upload/invoice-upload.module').then( m => m.InvoiceUploadPageModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('./inbox/inbox.module').then( m => m.InboxPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inbox-detail',
    loadChildren: () => import('./inbox-detail/inbox-detail.module').then( m => m.InboxDetailPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'video-list',
    loadChildren: () => import('./video-list/video-list.module').then( m => m.VideoListPageModule)
  },  {
    path: 'training-game',
    loadChildren: () => import('./training-game/training-game.module').then( m => m.TrainingGamePageModule)
  },
  {
    path: 'point-history',
    loadChildren: () => import('./point-history/point-history.module').then( m => m.PointHistoryPageModule)
  },
  {
    path: 'greetings',
    loadChildren: () => import('./greetings/greetings.module').then( m => m.GreetingsPageModule)
  },
  {
    path: 'greeting-listing',
    loadChildren: () => import('./greeting-listing/greeting-listing.module').then( m => m.GreetingListingPageModule)
  },











];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  providers: [AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }

