import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { appCommonMethods } from './appCommonMethods';


@Injectable()
export class AuthGuard implements CanActivate {

  
  restrictedMenuArray = [];
    constructor(public appcom: appCommonMethods){}

    ionViewDidEnter(){
        console.log('Auth Service');
    }

  public canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {
      console.log('state in auth guard', state, route, 'New : ', state.url.split(';')[0])
      if(this.restrictedMenuArray.indexOf(state.url.split(';')[0]) >= 0){
        this.appcom.showToast('You are not authorized for this section','bottom');
        return false;
      }
      else {
        return true;
      }
    }

}