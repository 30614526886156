export const APP_VERSION = "2.2.1";
export const APP_CODE = "20117";

export let FLAGS = {
    userFlag: ''
}

export let ALL_MESSAGE = {
    SUCCESS_MESSAGE: {

    },
    SERVER_MESSAGE: {
        COMMON_ERROR: "Something missing. Try again later"
    },
    ERROR_MESSAGE: {
        INVALID_MOBNO: "Please enter a valid mobile no",
        INVALID_NAME: "Please enter a valid name",
        RATING_NOT_SELECTED: "Please give us your rating",
        INVALID_OTP: "Please enter valid 6 digit OTP",
        RESEND_OTP: "OTP is sent to your mobile",
        NO_INTERNET: 'No internet connection'
    },
    COMMON_MESSAGE: {
        WAIT: "Please Wait....",
        APP_EXIT_TITLE: "Do you want to exit the app?",
        APP_EXIT_MESSAGE: "",
        NO_INTTERNET_CONNECTION: "No internet connection",
    },
    WAIT_MSG: {
        WAIT_ON_INIT: "Please Wait App will Start soon....."
    }
};
export let ALL_CONSTANTS = {
    BASE_URL: "https://cms.lenovoproconnect.com",       // API URL
    API_URL: "https://cms.lenovoproconnect.com/api",    // API URL live
    LIVE_URL: "https://www.lenovoevolve.com/",
    CSV_url: "https://cms.lenovoproconnect.com/sale-module/sample_file/sales_sample_web.csv",
    LIVE_API_URL: "https://cms.lenovoproconnect.com/api",
    LIVE_BASE_URL: "https://cms.lenovoproconnect.com",
    IS_STAGING: false,

    // old stagging urls
    // BASE_URL: "https://cmslenovot3.experiencecommerce.com",       // API URL
    // API_URL: "https://cmslenovot3.experiencecommerce.com/api",    // API URL live
    // LIVE_URL: "https://lenovot3.experiencecommerce.com/",
    // // CSV_url: "https://lenovot3.experiencecommerce.com/csv_sample/bulk_upload_template.csv",
    // CSV_url: "https://cmslenovot3.experiencecommerce.com/sale-module/sample_file/sales_sample_web.csv",
    // LIVE_API_URL: "https://cmslenovot3.experiencecommerce.com/api",
    // LIVE_BASE_URL: "https://cmslenovot3.experiencecommerce.com",
    // IS_STAGING: true,

    // //new stagging urls
    // BASE_URL: "https://stgcms.lenovoproconnect.com",       // API URL
    // API_URL: "https://stgcms.lenovoproconnect.com/api",    // API URL live
    // LIVE_URL: "https://stg.lenovoproconnect.com/",
    // // CSV_url: "https://stg.lenovoproconnect.com/csv_sample/bulk_upload_template.csv",
    // CSV_url: "https://stgcms.lenovoproconnect.com/sale-module/sample_file/sales_sample_web.csv",
    // LIVE_API_URL: "https://stgcms.lenovoproconnect.com/api",
    // LIVE_BASE_URL: "https://stgcms.lenovoproconnect.com",
    // IS_STAGING: true,

    DEFAULT_ENDPOINT: "/",
    NETWORK_CHECK_TIMEOUT: 2000,
    LOAD_MORE_LIMIT: 30,
    LIST_LIMIT: 5,
    LOAD_LIMIT: 10
}
let END_POINT_V1 = '/v1';
let END_POINT_V2 = '/v2';
export let API_ENDPOINT = {
    // registerUser: ALL_CONSTANTS.BASE_URL + '/ecmodules/updateUser-v4',
    registerUser: ALL_CONSTANTS.BASE_URL + '/ecmodules/updateUser-v6',  
    updateUserData: ALL_CONSTANTS.BASE_URL + '/ecmodules/updateAppDetails',
    saveUserPhoto: ALL_CONSTANTS.BASE_URL + '/ecmodules/saveUserPhoto',
    uploadPan: ALL_CONSTANTS.BASE_URL + '/ecmodules/savePancardPhotoV2',
    loginUser: ALL_CONSTANTS.BASE_URL + '/ecmodules/login-v2',
    loginT2User: ALL_CONSTANTS.BASE_URL + '/ecmodules/t2login',
    // verifyOtp: ALL_CONSTANTS.BASE_URL + '/ecmodules/verifyOtpT2-v2',
    verifyOtp: ALL_CONSTANTS.BASE_URL + '/ecmodules/verifyOtpT2-v4',
    // verifyGst: ALL_CONSTANTS.BASE_URL+'/ecmodules/verifyGstNo',
    verifyGst: ALL_CONSTANTS.BASE_URL + '/ecmodules/verifyGstNoV4',
    // insertVisit: ALL_CONSTANTS.BASE_URL + '/ecmodules/captureVisit',
    // insertVisit: ALL_CONSTANTS.BASE_URL + '/ecmodules/captureVisit-v2',
    insertVisit: ALL_CONSTANTS.BASE_URL + '/ecmodules/captureVisit-v3',
    updateRatings: ALL_CONSTANTS.BASE_URL + '/ecmodules/captureRatings-v2',
    insertQuery: ALL_CONSTANTS.BASE_URL + '/ecmodules/submitQuery',
    // insertNewT3Query: ALL_CONSTANTS.BASE_URL + '/ecmodules/submitNewT3Query',
    insertNewT3Query: ALL_CONSTANTS.BASE_URL + '/ecmodules/submitNewT3Query-V2',
    // updateQuery: ALL_CONSTANTS.BASE_URL + '/ecmodules/updateQuery',
    updateQuery: ALL_CONSTANTS.BASE_URL + '/ecmodules/updateQuery-V2',

    T2CdmsSalesClaim: ALL_CONSTANTS.BASE_URL + '/ecmodules/T2CdmsSalesClaim',
    insertCapture: ALL_CONSTANTS.BASE_URL + '/ecmodules/insertCaptureV2',
    config: ALL_CONSTANTS.BASE_URL + '/ecmodules/config',
    // getAllSales: ALL_CONSTANTS.API_URL+'/getAllSalesV2',
    getAllSales: ALL_CONSTANTS.API_URL + '/getAllSalesV2Web',
    getAllt2Sales: ALL_CONSTANTS.API_URL + '/gett2-sales-mtm',
    getT2SaleDetail: ALL_CONSTANTS.API_URL + '/gett2-sales-detail',
    checkSoClaim: ALL_CONSTANTS.API_URL + '/getso-sales-claim',

    getSaleDetail: ALL_CONSTANTS.API_URL + '/getSaleDetailV2',

    // product a[istarts
    getProductServiceTier: ALL_CONSTANTS.API_URL + '/getProductServiceTierV2',
    getProductCategory: ALL_CONSTANTS.API_URL + '/getProductCategoryV2',
    getProductBrand: ALL_CONSTANTS.API_URL + '/getProductBrandV2',
    getProductSeries: ALL_CONSTANTS.API_URL + '/getProductSeriesV2',
    getProductTabletSeries: ALL_CONSTANTS.API_URL + '/getProductTabletSeriesV2',
    getProductServersSeries: ALL_CONSTANTS.API_URL + '/getProductServersSeriesV2',
    getProductSubSeries: ALL_CONSTANTS.API_URL + '/getProductSubSeriesV2',
    getProductTabletSubSeries: ALL_CONSTANTS.API_URL + '/getProductTabletSubSeriesV2',
    getProductServersSubSeries: ALL_CONSTANTS.API_URL + '/getProductServersSubSeriesV2',

    getWarrantyProductCategory: ALL_CONSTANTS.API_URL + '/warrantyCategory',
    getWarrantyProductBrand: ALL_CONSTANTS.API_URL + '/warrantyBrands',
    getWarrantyProductSeries: ALL_CONSTANTS.API_URL + '/warrantySeries',
    getWarrantyProductSubSeries: ALL_CONSTANTS.API_URL + '/warrantySubSeries',


    getAccessoryProductCategory: ALL_CONSTANTS.API_URL + '/accessoryCategory',
    getAccessoryProductBrand: ALL_CONSTANTS.API_URL + '/accessoryBrands',
    getAccessoryProductSeries: ALL_CONSTANTS.API_URL + '/accessorySeries',
    getAccessoryProductSubSeries: ALL_CONSTANTS.API_URL + '/accessorySubSeries',
    getAccessorySubCategory: ALL_CONSTANTS.API_URL + '/accessorySubCat',

    getWarranty: ALL_CONSTANTS.API_URL + '/getWarranty',
    getServersWarranty: ALL_CONSTANTS.API_URL + '/getServersWarranty',
    getServersAccessory: ALL_CONSTANTS.API_URL + '/getServersAccessory',
    getAccessory: ALL_CONSTANTS.API_URL + '/getAccessory',
    getProducts: ALL_CONSTANTS.API_URL + '/getProductsV2',
    getServers: ALL_CONSTANTS.API_URL + '/getServersV2',
    getSubSeriesVideos: ALL_CONSTANTS.API_URL + '/getSubSeriesVideos',

    getProductNames: ALL_CONSTANTS.API_URL + '/seriesSearch',
    getProductNamesStaging: ALL_CONSTANTS.API_URL + '/seriesSearch',
    getProductImages: ALL_CONSTANTS.API_URL + '/getProductLargeImageV2',
    getSeriesImages: ALL_CONSTANTS.API_URL + '/getSubSeriesImagesV2',
    getProductSeriesData: ALL_CONSTANTS.BASE_URL + '/ecmodules/getSeriesDataV2',
    getSchemes: ALL_CONSTANTS.BASE_URL + '/ecmodules/getScheme',

    getMtm: ALL_CONSTANTS.API_URL + '/getMtmV2',

    //api for products ends//
    getBids: ALL_CONSTANTS.API_URL + '/getBidsV3',
    getLatestBid: ALL_CONSTANTS.API_URL + '/getLatestBid',
    getHighestBid: ALL_CONSTANTS.API_URL + '/getHighestBid',
    getBidCount: ALL_CONSTANTS.API_URL + '/getTotalBid',
    postBid: ALL_CONSTANTS.BASE_URL + "/ecmodules/postBid",

    // getRewardBrands: ALL_CONSTANTS.API_URL + '/getRewardBrands',
    getRewardBrands: ALL_CONSTANTS.API_URL + '/getRewardBrandsV1',
    getRewardCategory: ALL_CONSTANTS.API_URL + '/getRewardCategory',

    getBanners: ALL_CONSTANTS.API_URL + '/getBanners',
    // getQuery: ALL_CONSTANTS.API_URL + '/getQuery',
    getQuery: ALL_CONSTANTS.API_URL + '/getQuery-v1',
    getQueryDetail: ALL_CONSTANTS.API_URL + '/getQueryDetail',
    getVisitsNotRated: ALL_CONSTANTS.API_URL + '/getVisitsNotRatedV2',
    getVisits: ALL_CONSTANTS.API_URL + '/getVisits',
    // getVisitsV3: ALL_CONSTANTS.API_URL+'/getVisitsV3',
    getVisitsV3: ALL_CONSTANTS.API_URL + '/getVisits-v3',
    // getT3: ALL_CONSTANTS.API_URL + '/getT3Users-v4',
    getT3: ALL_CONSTANTS.API_URL + '/getT3Users-v6',
    getAso: ALL_CONSTANTS.API_URL + '/getT3Aso-v2',
    getSrd: ALL_CONSTANTS.API_URL + '/getT3Srd',
    getLep: ALL_CONSTANTS.API_URL + '/getT3Lep',
    getState: ALL_CONSTANTS.API_URL + '/getState',
    getCity: ALL_CONSTANTS.API_URL + '/getCity',
    getTerms: ALL_CONSTANTS.API_URL + '/getTnC',
    getT2Terms: ALL_CONSTANTS.API_URL + '/getT2TnC',
    getDefTerms: ALL_CONSTANTS.API_URL + '/getDefTnC',
    getAllTerms: ALL_CONSTANTS.BASE_URL + '/ecmodules/get-all-terms-v2',
    getFaq: ALL_CONSTANTS.API_URL + '/getFaq',
    getT2Faq: ALL_CONSTANTS.API_URL + '/getT2Faq',
    getWhatsNew: ALL_CONSTANTS.API_URL + '/getNews',
    getCSRActivity: ALL_CONSTANTS.API_URL + '/getCSRActivity',
    uploadCertificate: ALL_CONSTANTS.BASE_URL + '/ecmodules/uploadCertificate',
    getVideos: ALL_CONSTANTS.API_URL + '/getVideos-v1',
    registerUserRevision: ALL_CONSTANTS.BASE_URL + '/ecmodules/insertUserRevision',
    getProductOrderRequest: ALL_CONSTANTS.API_URL + '/getProductOrderRequest', // get product order request
    productOrderPlaced: ALL_CONSTANTS.API_URL + '/productOrderPlaced',
    insertProductOrder: ALL_CONSTANTS.BASE_URL + '/ecmodules/insertProductOrder', // get product placed order 
    getProductOrderRequestImages: ALL_CONSTANTS.API_URL + '/getProductOrderRequestImages',
    getProductLivestream: ALL_CONSTANTS.API_URL + '/getProductLivestream',// get product live stream data
    // getHomepagePop: ALL_CONSTANTS.API_URL + '/getHomepagePop',// get homepage pop up images
    getHomepagePop: ALL_CONSTANTS.API_URL + '/getHomepagePop-v2',// get homepage pop up images
    getComments: ALL_CONSTANTS.API_URL + '/getComments', // get all comment data
    commentProductOrderRequest: ALL_CONSTANTS.BASE_URL + '/ecmodules/commentProductOrderRequest', // insert comment  
    // getT3Revision: ALL_CONSTANTS.BASE_URL+'/api/getUserRevision'
    // getT3Revision: ALL_CONSTANTS.BASE_URL+'/api/getUserRevision',
    getRewardProducts: ALL_CONSTANTS.BASE_URL + '/api/getRewardProducts', //get product
    // generateRedemptionRequest: ALL_CONSTANTS.BASE_URL + '/ecmodules/generateRedemptionRequest',//generate Redemption Request
    generateRedemptionRequest: ALL_CONSTANTS.BASE_URL + '/ecmodules/generateRedemptionRequest-V4',//generate Redemption Request
    getActiveVoucher: ALL_CONSTANTS.BASE_URL + '/api/getActiveVouchers', //get all active voucher
    // getUserRewardsPoints: ALL_CONSTANTS.BASE_URL + '/ecmodules/userRewardConfig', //get user rewards points
    getUserRewardsPoints: ALL_CONSTANTS.BASE_URL + '/ecmodules/userRewardConfig-V3', //get user rewards points
    claimVoucher: ALL_CONSTANTS.BASE_URL + '/ecmodules/claimVoucher', // save claim voucher
    getRewardRedemptionHistory: ALL_CONSTANTS.BASE_URL + '/api/getRewardRedemptionHistory', //get redemption history
    getAllActiveVoucher: ALL_CONSTANTS.BASE_URL + '/api/all-vouchers-by-wallet',
    getExpiredVouchers: ALL_CONSTANTS.BASE_URL + '/api/getExpiredVouchers',  // get reward products
    saveEmployeeDetails: ALL_CONSTANTS.BASE_URL + '/ecmodules/insertCoownerEmployeeUserTypeV2', // save employee details
    getT3EmployeeDetails: ALL_CONSTANTS.BASE_URL + '/api/getUserDetailSingleShop', // get all t3 employee
    getT2EmployeeDetails: ALL_CONSTANTS.BASE_URL + '/api/getUserDetailSingleShop', // get all t3 employee
    deleteT3Employee: ALL_CONSTANTS.BASE_URL + '/ecmodules/deleteUserV2', // delete user member
    deleteT2Employee: ALL_CONSTANTS.BASE_URL + '/ecmodules/deleteUserV2',
    profileDataUpdate: ALL_CONSTANTS.BASE_URL + '/ecmodules/updatePartnerProfileDataT2-V2',
    getPartnerProfileDataUpdate: ALL_CONSTANTS.API_URL + '/getPartnerProfileDataUpdate',
    checkUserCertificates: ALL_CONSTANTS.BASE_URL + '/ecmodules/checkUserCertificates',
    acceptRewardPoints: ALL_CONSTANTS.BASE_URL + '/ecmodules/acceptRewardPoints',
    updateProfileCompleteness: ALL_CONSTANTS.BASE_URL + '/ecmodules/updateProfileCompleteness-v2', // update profile completeness
    getServiceContactMatrix: ALL_CONSTANTS.API_URL + '/getContactMatrix',// get contact matrix data
    getTaggedT2Users: ALL_CONSTANTS.API_URL + '/get-t2-users', // get t2 user data
    // getTaggedT2Users: ALL_CONSTANTS.API_URL + '/get-t2-users-v2', // get t2 user data
    getTaggedT3Users: ALL_CONSTANTS.API_URL + '/get-single-t3-users', // get t3 user data
    // getTaggedT3Users: ALL_CONSTANTS.API_URL + '/get-single-t3-users-v2', // get t3 user data
    getT3T2Data: ALL_CONSTANTS.API_URL + '/get-t3t2-users-v2', // get t3 and t2 user data
    // getT3T2Data: ALL_CONSTANTS.API_URL + '/get-t3t2-users-v3', // get t3 and t2 user data
    insertT2Query: ALL_CONSTANTS.BASE_URL + '/ecmodules/submitt2Query', // insert T2 query
    // insertNewT2Query: ALL_CONSTANTS.BASE_URL + '/ecmodules/submitNewt2Query', // insert T2 query Live
    insertNewT2Query: ALL_CONSTANTS.BASE_URL + '/ecmodules/submitNewt2Query_v2', // insert T2 query Live
    // getT2Query: ALL_CONSTANTS.API_URL + '/getT2Query', // get t2 query
    getT2Query: ALL_CONSTANTS.API_URL + '/getT2Query-v2', // get t2 query
    getT2queryDetial: ALL_CONSTANTS.API_URL + '/getT2QueryDetail', // get t2 query detail
    getT2QueryType: ALL_CONSTANTS.API_URL + '/contact-questionV2', // get T2 query type
    updateT2Query: ALL_CONSTANTS.BASE_URL + '/ecmodules/updateT2Query', //update T2 query
    saveSoSMDetails: ALL_CONSTANTS.BASE_URL + '/ecmodules/insertSoSmUserTypeV2',
    getServiceData: ALL_CONSTANTS.API_URL + '/lenovo-service',

    // Start here 17-11-2020
    getMonthlyTargets: ALL_CONSTANTS.BASE_URL + '/getMonthlyTargets',
    getMonthlyAchievements: ALL_CONSTANTS.BASE_URL + '/getMonthlyAchievements',
    getDailyAchievements: ALL_CONSTANTS.BASE_URL + '/getDailyAchievements',
    getSoSmWithAchievements: ALL_CONSTANTS.BASE_URL + '/ecmodules/getSoSmWithAchievements', //01-12-2020 add so/sm achivement
    getTshirtSize: ALL_CONSTANTS.BASE_URL + '/ecmodules/getTshirtSize',  //07-12-2020
    updateSOProfileCompleteness: ALL_CONSTANTS.BASE_URL + '/ecmodules/postUserData-v2',  //08-12-2020
    // End here

    //common login change api
    // loginUserV3: ALL_CONSTANTS.BASE_URL + '/ecmodules/loginAndSendOtpV3', //for mobile number login without role base config
    loginUserV3: ALL_CONSTANTS.BASE_URL + '/ecmodules/loginAndSendOtpCommon', // with rolebase config
    // verifyOtpV3: ALL_CONSTANTS.BASE_URL + '/ecmodules/verifyUserOtpV3', // without rolebase config
    verifyOtpV3: ALL_CONSTANTS.BASE_URL + '/ecmodules/verifyUserOtpV5', // with rolebase config
    // activateMultiRole: ALL_CONSTANTS.BASE_URL + '/ecmodules/activateMultiRole',
    activateMultiRole: ALL_CONSTANTS.BASE_URL + '/ecmodules/activateMultiRole-v2',
    //custom sale api
    customSaleApi: ALL_CONSTANTS.BASE_URL + '/sale-module/controller/api.php',
    // T2Custom sale clam api
    customeSaleApiT2: ALL_CONSTANTS.BASE_URL + '/sale-module/T2_sales/t2_api.php',
    getBrochure: ALL_CONSTANTS.API_URL + '/get-product-brochure',
    getHomepageBrochure: ALL_CONSTANTS.API_URL + '/get-homepage-product-brochure',

    ADD_CUSTOM_ATTRIBUTES: ALL_CONSTANTS.BASE_URL + '/ecmodules/get-moangage-customer',
    reorder: ALL_CONSTANTS.API_URL + '/app-homepage-section-ordering',
    // validateCreds: ALL_CONSTANTS.BASE_URL + '/webmodules/validateUsernamePassword',
    validateCreds: ALL_CONSTANTS.BASE_URL + '/ecmodules/validateUsernamePasswordV2',
    forgotPassword: ALL_CONSTANTS.BASE_URL + '/webmodules/forgotPassword',
    validateOtp: ALL_CONSTANTS.BASE_URL + '/webmodules/validateOtp',
    changePassword: ALL_CONSTANTS.BASE_URL + '/webmodules/changePassword',
    fetchUserDetails: ALL_CONSTANTS.BASE_URL + '/ecmodules/fetch-user-data-v2',
    // fetchUserDetails: ALL_CONSTANTS.BASE_URL + '/ecmodules/fetch-user-data-v3',
    getSelfRegister: ALL_CONSTANTS.BASE_URL + '/ecmodules/get-self-registered-role-list-v2',
    labelName: ALL_CONSTANTS.BASE_URL + '/label-name-list.json',
    appMenuList: ALL_CONSTANTS.BASE_URL + '/ecmodules/getAppMenuList',
    rolebasedConfigForGst: ALL_CONSTANTS.BASE_URL + '/ecmodules/role-based-config',
    rolebaseConfig: ALL_CONSTANTS.BASE_URL + '/ecmodules/role-based-data-config',
    // getPartners: ALL_CONSTANTS.API_URL + '/getT3UsersWeb-v4',
    getPartners: ALL_CONSTANTS.API_URL + '/getT3UsersWeb-v5',
    getAsoUsers: ALL_CONSTANTS.API_URL + '/getAsoUsersV2',
    getSrdUsersWithSale: ALL_CONSTANTS.API_URL + '/getSrdWithSaleCountV2',
    getLepUsersWithSale: ALL_CONSTANTS.API_URL + '/getLepWithSaleCountV2',
    getRcmUsers: ALL_CONSTANTS.API_URL + '/getRcmUsersV2',
    getRsmUsers: ALL_CONSTANTS.API_URL + '/getRsmUsersV2',
    getSalesHead: ALL_CONSTANTS.API_URL + '/getSalesHeadV2',
    getT3Det: ALL_CONSTANTS.API_URL + '/getT3UsersWeb-v2',
    updatePartnerProfileStatusByAso: ALL_CONSTANTS.BASE_URL + '/ecmodules/updatePartnerProfileStatusByAso',
    getAsoData: ALL_CONSTANTS.API_URL + '/getasoLeaderboard',
    getOverallAsoLeaderboard: ALL_CONSTANTS.API_URL + '/aso-leadershipboard',
    changeProfilePassword: ALL_CONSTANTS.BASE_URL + '/webmodules/changeProfilePassword',
    partnerSection: ALL_CONSTANTS.BASE_URL + '/ecmodules/get-lenovo-partner-list-v1',
    getVisitCount: ALL_CONSTANTS.API_URL + '/getVisitsCount',
    // validateCaptureBulk: ALL_CONSTANTS.BASE_URL + '/webmodules/validateSaleCaptureBulk',
    validateCaptureBulk: ALL_CONSTANTS.BASE_URL + '/webmodules/validateSaleCaptureBulkV2',
    getAllSubSeries: ALL_CONSTANTS.API_URL + '/getAllSubseries',
    bsharp: ALL_CONSTANTS.BASE_URL + "/ecmodules/get-all-bsharp-course-v2",
    homePageBsharp: ALL_CONSTANTS.BASE_URL + '/ecmodules/homepage-training-configuration-v2',
    fetchGameApi: ALL_CONSTANTS.BASE_URL + '/ecmodules/get-user-kahoot-detail-v2',
    Kahoot: ALL_CONSTANTS.BASE_URL + '/ecmodules/get-all-kahoot-quiz-v2',

    getPointDetails: ALL_CONSTANTS.API_URL + '/points-details',
    getPointCatalogue: ALL_CONSTANTS.API_URL + '/points-catalogue',
    getPointHistory: ALL_CONSTANTS.API_URL + '/points-history',
    getTopPicks: ALL_CONSTANTS.API_URL + '/top-picks',
    contentConsumption: ALL_CONSTANTS.BASE_URL + '/ecmodules/content-consumption',
    getClaimPoints: ALL_CONSTANTS.BASE_URL + '/ecmodules/get-claim-points',
    getGreetings: ALL_CONSTANTS.BASE_URL + '/api/greeting-listing',
    getGreetingDetail: ALL_CONSTANTS.BASE_URL + '/api/greeting-detail',
    greetingHomePop: ALL_CONSTANTS.BASE_URL + '/api/greeting-home-pop-up',
    joiningBonusViewed: ALL_CONSTANTS.BASE_URL + '/api/bonus-points-popup-close',
    getCompatitorBrands: ALL_CONSTANTS.BASE_URL + '/ecmodules/getCaptureVisitBrandList',
    uploadGstCertificate: ALL_CONSTANTS.BASE_URL + '/ecmodules/saveGstCertificatePhotoV1',
    getASOVisitCompetitorsData: ALL_CONSTANTS.BASE_URL + '/ecmodules/get-aso-data-by-id-v1',
    
}
export let MBSC_SELECT_SETTING = {
    theme: 'material',
    display: 'center',
    rows: 5,
    filter: true,
    height: 60
}
export let ALL_PATTERN = {

    NAME: "(?![0-9\\s+]*$)[a-zA-Z\\s-_.']+",
    NAME_NUMBER: "(?![0-9\\s+]*$)[a-zA-Z\\s-_.'0-9]+",
    NEW_NAME: "^[a-zA-Z']+( [a-zA-Z']+)*$",
    NUMBER: "^(\\s*\\)?|[0]?)?[6789]\\d{9}$",
    PINCODE: "^[1-9][0-9]{5}$",
    EMAIL: "\\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}\\b",
    MOBILE_NUMBER: "^[6-9]\d{9}$",
    EMOJI: /^[a-zA-Z0-9@()?,. &'\s- \ud000-\udfff\ud000-\udfff\ud000-\udfff\u2000-\u3300]+$/
}

export let months = [
    { text: 'January', value: '01' },
    { text: 'February', value: '02' },
    { text: 'March', value: '03' },
    { text: 'April', value: '04' },
    { text: 'May', value: '05' },
    { text: 'June', value: '06' },
    { text: 'July', value: '07' },
    { text: 'August', value: '08' },
    { text: 'September', value: '09' },
    { text: 'October', value: '10' },
    { text: 'November', value: '11' },
    { text: 'December', value: '12' },
  ]
