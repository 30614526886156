import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { appCommonMethods } from 'src/services/appCommonMethods';
import { ALL_CONSTANTS } from 'src/services/config';
// import { Events } from '@ionic/angular';

declare var window:any;

@Component({
  selector: 'app-footer-tabs',
  templateUrl: './footer-tabs.component.html',
  styleUrls: ['./footer-tabs.component.scss'],
})

export class FooterTabsComponent implements OnInit {

  @Input() activePage:string;
  
  userData: any = {};
  callStatus:string = 'Pending';
  asoStatus: any;
  manage_user_type:any;
  showFooterBtn = false;
  platform = true;
  currentRole;
  
  constructor(
    public router: Router,
    public nativeStorage: NativeStorage,
    private firebaseAnalytics: FirebaseAnalytics,
    // public events: Events,
    private appCom: appCommonMethods
  ) { 
    this.platform = this.appCom.platform.is('cordova') ? true : false;
    this.appCom.showFooterSub.subscribe((res:any) => {
      if(res){
        this.appCom.getLocalStorageItem('userData').then((resData:any) => {
          console.log('resdTaa : ', resData)
          this.currentRole = resData.data.role.toLowerCase();
          if(((resData.data.call_status.toLowerCase() == 'verified' && resData.data.aso_status.toLowerCase() != 'rejected' )  || (resData.data.manage_user_type == 'coowner' || resData.data.manage_user_type == 'employee')) &&  resData.data.role.toLowerCase() == 't3'){
          this.showFooterBtn = true;
          }
          else {
            this.showFooterBtn = false;
          }
        })
      }
      else{
        this.showFooterBtn = false;
      }
    })
  }

  bulkUpload(){
    this.router.navigate(['/invoice-upload']);
  }

  ngOnInit() {
    console.log("activePage",this.activePage);
    this.initFooterUserData();
  }

  addSale(){
    this.router.navigate(['/sale-capture-add']);
  }

  changePage(page){
    this.appCom.getLocalStorageItem('menuArray').then( (resData: any) => {
      console.log('Menu array : ',resData)
      resData.filter(item => {
        console.log('footer page : ', page, item.machine_name);
        if(item.machine_name == page){
          this.router.navigate([item.route]);
        }
      });
    })

    return
    if(page != this.activePage){
      switch (page) {
        case 'home':
          // this.events.publish('menu:pagechange', true, 'Home');
          this.router.navigate(['/home']);
          break;
        case 'products':
          // this.events.publish('menu:pagechange', true, 'Product Catalogue');
        this.router.navigate(['/category-list']);
          break;
        case 'schemes':
          // this.events.publish('menu:pagechange', true, 'Schemes');
        this.router.navigate(['/scheme']);
          break;
        case 'query':
          // this.events.publish('menu:pagechange', true, 'Query');
        this.router.navigate(['/query']);
          break;
        case 'sale-capture':
          // this.events.publish('menu:pagechange', true, 'Sale Capture');
          if(!ALL_CONSTANTS.IS_STAGING){
            this.firebaseAnalytics.logEvent("sale_capture", {content_type:"click",item_id:"Add Sale"});
          }
          this.router.navigate(['/sale-capture']);
          break;
        case 'sales-capture-t2':
            // this.events.publish('menu:pagechange', true, 'Sale Capture');
            this.router.navigate(['/sales-capture-t2']);
            break;
        default:
          console.log('on same page',);
          break;
      }
    }
  }

  initFooterUserData(){
    this.appCom.getLocalStorageItem("userData").then(async (data:any) => {
      if(data){
        this.userData = await data.data;
        if(this.userData.call_status){
          this.callStatus =  (this.userData.call_status).toLowerCase();
        }

        if(this.userData.aso_status){
          this.asoStatus = (this.userData.aso_status).toLowerCase();
        }

        if(this.userData.manage_user_type){
          this.manage_user_type = (this.userData.manage_user_type).toLowerCase();
        }

        if(((this.callStatus == 'verified' && this.asoStatus != 'rejected' )  || (this.manage_user_type == 'coowner' || this.manage_user_type == 'employee')) &&  this.userData.role.toLowerCase() == 't3'){
          this.showFooterBtn = true;
        }

      }
    });
  }
}
