//import {Http, Headers, RequestOptions} from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { ALL_CONSTANTS, API_ENDPOINT } from './config';
import { appCommonMethods } from './appCommonMethods';

import { tap, map } from 'rxjs/operators';
@Injectable()
export class GetData {

    updateDate: string;
    constructor(private appCom: appCommonMethods, private http: HttpClient) {
        this.http = http;
    }

    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json"

        })

    }

    private createFormHeader(headers: Headers) {
        this.httpOptions.headers = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded"

        })
    }

    private createFormAuthorizationHeader(headers: Headers) {
        this.httpOptions.headers = new HttpHeaders({
            "Content-Type": "application/x-www-form-urlencoded",
            'Authorization': 'Basic ' + btoa('T3_APP:^^85rwCXulNR23')

        })
        // headers.append('Access-Control-Allow-Headers','Origin, X-Requested-With, Authorization, Content-Type, Accept, filter');
        // this.httpOptions.headers.append('Authorization', 'Basic ' + btoa('T3_APP:^^85rwCXulNR23'));
    }

    private createAuthorizationForGetData(headers: Headers) {
        this.httpOptions.headers = new HttpHeaders({
            "Content-Type": "application/json",
            'Authorization': 'Basic ' + btoa('T3_APP:^^85rwCXulNR23')

        });
        // headers.append('Access-Control-Allow-Headers','*');
        // headers.append('Access-Control-Allow-Origin' , '*');
        // headers.append('Access-Control-Allow-Methods', 'POST, GET');
        // this.httpOptions.headers.append('Authorization', 'Basic ' + btoa('T3_APP:^^85rwCXulNR23'));
    }

    getDataFromApi(api, params = null, offset = null) {// get data from api needs api end point and params object
        let headers = new Headers();
        // this.createAuthorizationForGetData(headers);
        if (api == API_ENDPOINT.getTshirtSize) {
            this.createFormHeader(headers);
        }
        else {
            this.createAuthorizationForGetData(headers);
        }
        console.log("Header ", this.httpOptions);
        // let options = new RequestOptions({ headers: headers });        
        let endpoint_url = api;
        if (params != null) {
            endpoint_url += "?";
            for (let key in params) {
                endpoint_url += key + "=" + params[key];
                endpoint_url += "&";
            }
            endpoint_url = endpoint_url.substr(0, endpoint_url.length - 1);
        }
        if (offset != null) {
            if (params == null) {
                endpoint_url += 'offset=' + offset;
            } else {
                endpoint_url += '&offset=' + offset;
            }
        }
        try {
            // return new Promise((resolve, reject) => {
            //     this.http.get(endpoint_url, this.httpOptions).subscribe(res => {
            //         resolve(res);
            //     });
            // })
            // return request;
            // return  this.http.get(endpoint_url,this.httpOptions)
            // .do((res : Response ) => console.log(res.json())
            // .map((res : Response ) => res.json())
            return this.http.get(endpoint_url, this.httpOptions
            ).pipe(map(user => {
                // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                // alert(JSON.stringify(user));
                return user;
            }));
        } catch (error) {
            console.log('error from net', error);
        }
    }

    postDataFromApi(api, params = null) {
        let headers = new Headers();
        this.createAuthorizationForGetData(headers);
        //let options = new RequestOptions({ headers: headers });
        try {
            let request = this.http.post(api, params, this.httpOptions).subscribe(res => res);
            return request;
        } catch (error) {
            console.log('this is err', error);
        }
    }

    postDataFromApiCustom(api, params = null) {
        let headers = new Headers();

        // if(api == API_ENDPOINT.verifyOtpV3 || api == API_ENDPOINT.loginUserV3 || api == API_ENDPOINT.loginUser || api == API_ENDPOINT.verifyOtp || api == API_ENDPOINT.verifyGst || api == API_ENDPOINT.loginT2User || api == API_ENDPOINT.getTshirtSize){
        //     this.createFormHeader(headers);
        // }
        if (api == API_ENDPOINT.loginUser ||
            api == API_ENDPOINT.verifyOtp || api == API_ENDPOINT.verifyGst || api == API_ENDPOINT.loginT2User ||
            api == API_ENDPOINT.getTshirtSize || api == API_ENDPOINT.loginUserV3 || api == API_ENDPOINT.verifyOtpV3) {
            this.createFormHeader(headers);
        }
        else {
            this.createFormAuthorizationHeader(headers);
        }

        //let options = new RequestOptions({ headers: headers });        
        let endpoint_url = api;
        let param_url = "";
        if (params != null) {
            for (let key in params) {
                param_url += key + "=" + encodeURIComponent(params[key]);
                param_url += "&";
            }
            /*for (let key in params) {
                param_url[key] = params[key];
                //param_url+="&";
            }*/
            param_url = param_url.substr(0, param_url.length - 1);
        }
        console.log('api', api);
        // this.httpOptions = {
        //     headers: new HttpHeaders({
        //         "Content-Type": "application/x-www-form-urlencoded"
        //     })

        // }
        console.log('headers : ', this.httpOptions)
        try {
            // return new Promise((resolve, reject) => {
            //     let request = this.http.post(api, param_url, this.httpOptions).subscribe(res => {
            //         resolve(res);
            //     }, err => {
            //         reject(err);
            //     });
            // })
            // return request;
            //     var result = new Observable();
            //    this.http.post(api,param_url, this.httpOptions).subscribe(res => {
            //    result.next(res);
            //    });
            return this.http.post(api, param_url, this.httpOptions).pipe(map(user => {
                // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                // alert(JSON.stringify(user));
                return user;
            }));
        } catch (error) {
            console.log('this is err', error);
        }
    }
    decodehtml(params) {
        for (let key in params) {
            if (typeof (params[key]) == 'string') {
                params[key] = this.appCom.decodeHTMLEntities(params[key]);
            }
        }
        return params;
    }

    getLabelFromJson() {
        let todayDate: any = new Date()
        let time = Math.floor(todayDate);
        return this.getDataFromApi(API_ENDPOINT.labelName + '?v=' + time);
    }

    getSelfRegisetrFromApi() {
        return this.postDataFromApiCustom(API_ENDPOINT.getSelfRegister);
    }

    getRolebasedConfigForGst(param) {
        return this.postDataFromApiCustom(API_ENDPOINT.rolebasedConfigForGst, param);
    }

    postDataSerialize(api, params = null) {
        // return;
        let headers = new Headers();
        this.createFormAuthorizationHeader(headers);
        // let options = new RequestOptions({ headers: headers });        
        let endpoint_url = api;
        let param_url = "";
        if (params != null) {
            for (let key in params) {
                param_url += key + "=" + encodeURIComponent(params[key]);
                param_url += "&";
            }
            /*for (let key in params) {
                param_url[key] = params[key];
                //param_url+="&";
            }*/
            param_url = param_url.substr(0, param_url.length - 1);
        }
        console.log('api', api);
        try {
            return this.http.post(api, param_url, this.httpOptions).pipe(map(res => {
                return res;
            }))
        } catch (error) {
            console.log('this is err', error);
        }
    }

    contentConsumption(nid, type, userData) {
        let device;
        if (this.appCom.platform.is('cordova')) {
            device = this.appCom.platform.is('android') ? 'android' : 'ios';
        } else {
            device = 'web_app';
        }

        if (this.appCom.platform.is('cordova')) {
            var postData = {};
            postData['user_id'] = userData.userId;
            postData['nid'] = nid;
            postData['mobile'] = userData.mobile;
            postData['owner_mail'] = userData.owner_mail ? userData.owner_mail : '';
            postData['type'] = type.toLocaleLowerCase();
            postData['device'] = device;
            // postData['role'] = this.userData.role.toLowerCase();
            // postData['aso_type'] = this.userData.aso_type.toLowerCase();

            try {
                return this.postDataFromApiCustom(API_ENDPOINT.contentConsumption, postData).pipe(map(response => {
                    var res = response;
                    if (res['status'] && res['status'] == 'Success' && res['message'] && res['message'] != "") {
                        let msg = '<img src="./assets/images/coins.svg" alt="">  &nbsp;' + res['message'];
                        this.appCom.showCustomToast(msg, 'bottom');
                    }
                    return res;
                }))
            } catch (error) {
                console.log('this is err', error);
            }

            // this.postDataFromApiCustom(API_ENDPOINT.contentConsumption, postData).subscribe((res: any) => {
            //     console.log('res : ', res)
            //     if (res.status && res.status == 'Success' && res.message && res.message != ""){       
            //         let msg = '<img src="./assets/images/coins.svg" alt="">  &nbsp;'+ res.message;         
            //         this.appCom.showCustomToast(msg, 'bottom');
            //     }
            //     return res;
            // }, err => {
            // })
        }
    }

    contentConsumptionStatic(nid, type, userData) {
        let device;
        if (this.appCom.platform.is('cordova')) {
            device = this.appCom.platform.is('android') ? 'android' : 'ios';
        } else {
            device = 'web_app';
        }

        if (this.appCom.platform.is('cordova')) {
            var postData = {};
            postData['user_id'] = userData.userId;
            postData['nid'] = nid;
            postData['mobile'] = userData.mobile;
            postData['owner_mail'] = userData.owner_mail ? userData.owner_mail : '';
            postData['type'] = type.toLocaleLowerCase();
            postData['device'] = device;
            // postData['role'] = this.userData.role.toLowerCase();
            // postData['aso_type'] = this.userData.aso_type.toLowerCase();

            this.postDataFromApiCustom(API_ENDPOINT.contentConsumption, postData).subscribe((res: any) => {
                console.log('res : ', res)
                if (res.status && res.status == 'Success' && res.message && res.message != ""){       
                    let msg = '<img src="./assets/images/coins.svg" alt="">  &nbsp;'+ res.message;         
                    this.appCom.showCustomToast(msg, 'bottom');
                }
                return res;
            }, err => {
            })
        }
    }
}


