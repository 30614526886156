import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { appCommonMethods } from "./appCommonMethods";
import { API_ENDPOINT, APP_VERSION } from "./config";
import { GetData } from "./GetData";
import { first } from "rxjs/operators";
import { NativeStorage } from "@awesome-cordova-plugins/native-storage/ngx";

declare var MoECordova: any;

@Injectable({
  providedIn: "root",
})
export class MoengagePushService {
  constructor(private _router: Router, private _rest: GetData, private appCom: appCommonMethods,public nativeStorage: NativeStorage,) { }

  setCustomAttribute(user, token?) {
    console.log('user Data Moengage : ', user)
    let params = {
      "type": "customer",
      "customer_id": user["userId"],
      "attributes": {
        "userId":user["userId"],
        "first_name": user["name"].split(" ")[0],
        "last_name": user["name"].split(" ")[1],
        "name": user["name"],
        "Role": user.role.toLowerCase() == 'lenovo partner' ? 't3' : user.role,
        "Call status": user.call_status,
        "Gst verified": !!user.gstn_no,
        "App version": APP_VERSION,
        "City": user.cityName,
        "State": user.stateName,
        "Pan status": user.pan_status,
        "Region": user.regionMapping,
        "Balance Point": user.currentPoints ? user.currentPoints.toString() : null,
        "Redeemed Points": user.redeemed_points ? user.redeemed_points.toString() : null,
        "mobile_number": user.mobile,
        "token": token,
        "type of business": user.type_of_business,
        "Profile VerificationStatus": user.profileStatus,
        'call_verification_date' :user.call_verification_date,
        'gstn_verification_date' : user.gst_verification_date,
        'pan_verification_date' : user.pan_verification_date
      }
    }
    // this._rest.postDataFromApiCustom(API_ENDPOINT.ADD_CUSTOM_ATTRIBUTES, { data: JSON.stringify(params) }).then((res) => {
    //   console.log(res);
    // }, err => {
    //   console.log(err);
    // })

    this._rest.postDataFromApiCustom(API_ENDPOINT.ADD_CUSTOM_ATTRIBUTES, { data: JSON.stringify(params) }).pipe(first()).subscribe((res) => {
      console.log(res);
    }, err => {
      console.log(err);
    })

  }

  registerForPush(userDetails, token?) {

    this.appCom.getLocalStorageItem("firebaseToken").then((res:any) => {
      console.log(res.data);
      var moe = new MoECordova.init();
      
    if (!localStorage.getItem("MOENGAGE_REGISTER")) {
      moe.setAppStatus("INSTALL");
      localStorage.setItem("MOENGAGE_REGISTER", "true");
    } else moe.setAppStatus("UPDATE");

    console.log("Moengage user Details ", userDetails);

    moe.setUniqueId(userDetails["userId"]);
    moe.setUserName(userDetails["name"]);
    console.log(token, "inside moengage ")
    token && moe.passFcmToken(res.data);
    // moe.passFcmToken();
    // moe.passFcmPayload()
    moe.showInApp();
    
    if(userDetails)
    this.setCustomAttribute(userDetails, token);

    // Dont know the use
    moe.getSelfHandledInApp();

    // this.appCom.moengageUserLogout.subscribe((res) => {
    //   // res && moe.logout();
    // })
    // moe.registerForPushNotification();
    moe.on("onPushClick", (payloadInfo) => {
      console.log("onPushClick ", payloadInfo);


      if (payloadInfo.platform == "android") {
        // For android
      payloadInfo.payload.gcm_webUrl && this.navigateViaDeeplink(payloadInfo.payload.gcm_webUrl)
      }
      else {
        // For ios 
      payloadInfo.payload.app_extra.moe_deeplink && this.navigateViaDeeplink(payloadInfo.payload.app_extra.moe_deeplink)
      }

      

    });

    moe.on("onInAppClick", (inAppInfo) => {
      console.log(
        "InApp Shown Clicked with Info: " + JSON.stringify(inAppInfo)
      );
      if (inAppInfo.navigation.navigationType == "deep_linking") {
        this.navigateViaDeeplink(inAppInfo.navigation.value)
      }
    });

    /*************************** Now not in use ****************************/

    moe.on("onInAppSelfHandle", function (selfHandledPayload) {
      console.log(
        "Self hanlded InApp Info: " + JSON.stringify(selfHandledPayload)
      );
    });

    moe.on("onInAppDismiss", function (inAppInfo) {
      console.log("InApp Dismissed with Info: " + JSON.stringify(inAppInfo));
    });

    moe.on("onInAppCustomAction", function (inAppInfo) {
      console.log(
        "InApp Custom Action with Info: " + JSON.stringify(inAppInfo)
      );
    });

    /*************************** Now not in use ****************************/

    
      
    });
    
    
  }

  navigateViaDeeplink(link: string) {
    if (link && localStorage.getItem("MOENGAGE_REGISTER")) {
      let route = link.split("?");
      let internalLink: any = route;
      console.log('Moengage : ', route, internalLink)
      if (route.length > 1) {
        let param = route[1].split("&");
        let queryParams = {};
        param.forEach((element) => {
          let key = element.split("=")[0];
          let value = element.split("=")[1];
          queryParams[key] = value;
        });
        internalLink = [route[0], queryParams];
        console.log('Moengage if : ', internalLink)
      }
      this.appCom.moengageNotificationClicked.next(internalLink);
    } else {
      this._router.navigate(['/login']);
    }
  }
}