import { MbscModule } from '@mobiscroll/angular';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NativeStorage } from '@awesome-cordova-plugins/native-storage/ngx';
import { Network } from '@ionic-native/network/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { AutosizeModule } from 'ngx-autosize';
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { ShareService } from '../services/ShareService';
import { appCommonMethods } from '../services/appCommonMethods';
import { GetData } from '../services/GetData';
import { AuthenticationService } from '../services/AuthenticationService';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NoInternetConnectionPageModule } from './no-internet-connection/no-internet-connection.module';

import { AlertController, LoadingController, ModalController, ToastController, Platform } from '@ionic/angular';//
//import { HttpModule } from '@angular/http';

import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { EmailComposer } from '@ionic-native/email-composer/ngx';
import { FooterTabsComponent } from './components/footer-tabs/footer-tabs.component';
import { DatePipe } from '@angular/common';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx'; 
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions } from '@awesome-cordova-plugins/media-capture/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { AuthGuard } from 'src/services/authServices';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx'
import { Device } from '@ionic-native/device/ngx';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [ 
    MbscModule, 
    FormsModule,
    BrowserModule,
    IonicModule.forRoot({
      backButtonText: '',
      mode:'md'
    }
    ),
    AppRoutingModule,
    HttpClientModule,
    AutosizeModule,
    NoInternetConnectionPageModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    NativeStorage,
    Network,
    ScreenOrientation,
    ShareService,
    AuthGuard,
    AuthenticationService,
    FirebaseAnalytics,
    appCommonMethods,
    GetData,
    Camera,
    MediaCapture,
    Base64,
    AndroidPermissions,
    CallNumber,
    Geolocation,
    FirebaseX,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AlertController,
    LoadingController,
    
    ModalController,
    ToastController,
    Platform,
    AppVersion,
    File,
    FileTransfer,
    FilePath,
    LocalNotifications,
    FileOpener,
    InAppBrowser,
    BarcodeScanner,
    SocialSharing,
    Clipboard,
    EmailComposer,
    FooterTabsComponent,
    AppAvailability,
    // Raju Bootla 17-11-2020
    DatePipe,
    // End here 17-11-2020
    Deeplinks,
    Device,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(translate: TranslateService, nativeStorage: NativeStorage, platform: Platform, appcom: appCommonMethods) {
    platform.ready().then( () => {
      appcom.getLocalStorageItem("language").then((data:any) => {
        console.log(data);
        if (data) {
          translate.setDefaultLang(data.lang);
        }
        else {
          translate.setDefaultLang('en');
        }
      },
        (err) => {
          console.log(err);
          translate.setDefaultLang('en');
        })
    });
  }
}