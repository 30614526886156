import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})

export class AuthenticationService {

    role: string;
    userRole;
    ownerArr = ['/home', '/profile', '/sale-capture', '/user-management', '/category-list', '/whats-new', '/bid-list', '/scheme', '/reward-catalogue', '/voucher-listing', '/product-request', '/videos', '/contact-matrix', '/rate-visit', '/query', '/language-new', '/terms', '/faq'];
    coempArr = ['/home', '/profile', '/sale-capture', '/category-list', '/whats-new', '/bid-list', '/scheme', '/reward-catalogue', '/product-request', '/videos', '/contact-matrix', '/rate-visit', '/query', '/language-new', '/terms', '/faq'];
    t2Arr = ['/home', '/profile', '/user-management', '/category-list', '/whats-new', '/bid-list', '/scheme', '/voucher-listing', '/product-request', '/videos', '/contact-matrix', '/rate-visit', '/query', '/language-new', '/terms', '/faq'];
    rsmArr = ['/home', '/profile', '/sale-capture', '/sales-capture-t2', '/retailer-list', '/category-list', '/whats-new', '/bid-list', '/scheme', '/reward-catalogue', '/product-request', '/videos', '/contact-matrix', '/language', '/terms', '/switch-aso', '/change-password', '/matrix', '/csr-activity', '/faq'];
    srdArr = ['/home', '/profile', '/sale-capture', '/retailer-list', '/category-list', '/whats-new', '/bid-list', '/scheme', '/reward-catalogue', '/product-request', '/videos', '/contact-matrix', '/language', '/terms', '/change-password', '/matrix', '/csr-activity'];
    userData: any = {};
    public appPages = [
        {
            title: 'Home',
            url: '/home',
            icon: 'icon-home',
            icon_active: 'icon-home-active',
            open: false,
            status: true
        },
        {
            title: 'My Business',
            icon: 'icon-portfolio',
            open: false,
            status: true,
            children: [
                {
                    title: 'Profile',
                    url: '/profile',
                    open: true,
                    icon: 'icon-profile-icon',
                    isChild: false
                },
                {
                    title: 'Profile',
                    url: '/profile-srd',
                    open: true,
                    icon: 'icon-profile-icon',
                    isChild: true
                },
                {
                    title: 'Sales Capture',
                    url: '/sale-capture',
                    open: false,
                    icon: 'icon-sale-capture',
                    isChild: true
                },
                {
                    title: 'Sales Claim',
                    url: '/sales-capture-t2',
                    open: false,
                    icon: 'icon-sale-capture',
                    isChild: true
                },
                {
                    title: 'Add Employees',
                    url: '/user-management',
                    open: false,
                    icon: 'icon-user-management',
                    isChild: true
                },
                {
                    title: 'Add Sales Personnel',
                    open: false,
                    url: '/user-management-t2',
                    icon: 'icon-user-management',
                    isChild: true
                },
                {
                    title: 'My Performance',
                    url: '/performance-chart-t2',
                    open: true,
                    icon: 'icon-leaderboard',
                    isChild: false
                },
                {
                    title: 'ASO Visits',
                    url: '/rate-visit',
                    open: false,
                    icon: 'icon-aso-visits',
                    isChild: true
                },
                {
                    title: 'Query',
                    url: '/query',
                    open: false,
                    icon: 'icon-icon-chat',
                    isChild: true
                },
                {
                    title: 'Inbox',
                    url: '/inbox',
                    open: false,
                    icon: 'icon-contact-us',
                    isChild: true
                },
                {
                    title: "T3",
                    url: "/retailer-list",
                    icon: "icon-retailers",
                    open: false,
                    isChild: true
                },
            ]
        },
        {
            title: 'Benefits',
            icon: 'icon-proconnect-benefits',
            open: false,
            status: true,
            children: [
                {
                    title: 'Schemes',
                    url: '/scheme',
                    open: false,
                    icon: 'icon-schemes',
                    isChild: true
                },
                {
                    title: 'ProConnect Exclusives',
                    url: '/product-request',
                    open: false,
                    icon: 'icon-rate',
                    isChild: true
                },
                {
                    title: 'Bids',
                    url: '/bid-list',
                    open: false,
                    icon: 'icon-bid',
                    isChild: true
                },
                {
                    title: 'My Loyalty Points',
                    url: '/reward-catalogue',
                    open: false,
                    icon: 'icon-reward-catloge',
                    isChild: true
                },
                {
                    title: 'Redemption History',
                    url: '/voucher-listing',
                    open: false,
                    icon: 'icon-redemption-history',
                    isChild: true
                },
                {
                    title: 'Point History',
                    url: '/point-history',
                    open: false,
                    icon: 'icon-point-history',
                    isChild: true
                },
                {
                    title: 'Greetings',
                    url: '/greeting-listing',
                    open: false,
                    icon: 'icon-birthday-greeting',
                    isChild: true
                },
                {
                    title: "Reward Points",
                    url: "/reward-point",
                    icon: "icon-leaderboard",
                    open: false,
                    isChild: true
                },
                {
                    title: 'Referral Code',
                    url: '/referral',
                    icon: 'icon-referal-code',
                    open: false,
                    isChild: true
                },
                {
                    title: 'Change Password',
                    url: '/change-password',
                    icon: 'icon-password',
                    open: false,
                    isChild: true
                },

            ]
        },
        {
            title: 'Products & Updates',
            icon: 'icon-brand-speaks',
            status: true,
            open: false,
            children: [
                {
                    title: 'Whats new',
                    url: '/whats-new',
                    open: false,
                    icon: 'icon-news',
                    isChild: true
                },
                {
                    title: 'Brochures',
                    url: '/brochure-download',
                    open: false,
                    icon: 'icon-brochure-line',
                    isChild: true
                },
                {
                    title: 'Product Catalogue',
                    url: '/category-list',
                    open: false,
                    icon: 'icon-product',
                    isChild: true
                },
                {
                    title: 'Videos',
                    url: '/videos',
                    open: false,
                    icon: 'icon-play',
                    isChild: true
                }
            ]
        },
        {
            title: 'Learn to Evolve',
            url: '/training-game',
            icon: 'icon-noun-learning',
            open: false,
            status: true
        },
        {
            title: 'Services Upsell',
            url: '/services',
            open: false,
            icon: 'icon-upsell',
            status: true
        },
        {
            title: 'Lenovo Cares',
            url: '/csr-activity',
            open: false,
            icon: 'icon-csr',
            status: true
        },
        {
            title: 'Language',
            url: '/language-new',
            icon: 'icon-language',
            open: false,
            status: true
        },
        {
            title: 'Service Contact Matrix',
            url: '/contact-matrix',
            open: false,
            icon: 'icon-service-contract-matrix',
            status: true
        },
        {
            title: 'FAQs',
            url: '/faq',
            icon: 'icon-faq',
            open: false,
            status: true
        },

        // {
        //   title: 'Product Launch ',
        //   url: '/launch-live',
        //   icon: 'icon-terms-conditions'
        // },

        // {
        //   title: 'Escalation Matrix',
        //   url: '/escalation-matrix',
        //   icon: 'icon-play'
        // },


        {
            title: 'Terms',
            url: '/terms',
            icon: 'icon-terms-conditions',
            open: false,
            status: true
        },
        // {
        //     title: 'Switcher',
        //     url: '/switch',
        //     icon: 'icon-switcher',
        //     open: false,
        //     status: false,
        // },
        // {
        //     title: 'Activate',
        //     url: '/activate-role',
        //     icon: 'icon-refer',
        //     open: false,
        //     status: false
        // },
        {
            title: 'Logout',
            url: '/logout',
            open: false,
            icon: 'icon-logout',
            status: true
        },

        // {
        //   title: 'catalouge-detail',
        //   url: '/catalouge-detail',
        //   icon: 'icon-graduate-diploma'
        // },
        // {
        //   title: 'voucher-listing',
        //   url: '/voucher-listing',
        //   icon: 'icon-graduate-diploma'
        // }
    ];
    public menuArray = [];
    public restirctedArray = [];

    constructor() { }

    isAuthenticated(url) {
        // console.log('called url : ', url);
        // if role is empty, get the role
        // if (!this.role) {
        //     this.getUserDetails();
        // }
        // else {
        //     if (this.role == "owner" && this.ownerArr.indexOf(url) >= 0) {
        //         return true;
        //     }
        //     else if ((this.role == "coowner" || this.role == "employee") && this.coempArr.indexOf(url) >= 0) {
        //         return true;
        //     } else if (this.role == "t2" && this.t2Arr.indexOf(url) >= 0) {
        //         return true;
        //     } else if ((this.userRole == 'srd' || this.userRole == 'Srd') && this.srdArr.indexOf(url) >= 0) {
        //         return true;
        //     }
        //     else {
        //         return false;
        //     }

        // console.log('menu adta : ', this.menuArray, this.restirctedArray)
        if (this.menuArray.indexOf(url) >= 0) {
            if (this.restirctedArray.indexOf(url) >= 0) {
                // console.log('url1 : ', url);
                return false;
            }
            else {
                // console.log('url2 : ', url)
                return true;
            }
        }
        else {
            return false;
        }

        // }
        // console.log("CheckFor ", this.role, url);
    }

    // async getUserDetails() {
    //     var data = window.localStorage.getItem('userData');
    //     this.userData = JSON.parse(data);
    //     console.log('Data role : ', this.userData);
    //     if (this.userData && this.userData['data'] && this.userData['data']['role'] != 't2') {
    //         if (this.userData && this.userData['data'] && this.userData['data']['manage_user_type']) {
    //             this.role = this.userData['data']['manage_user_type'];
    //         } else {
    //             // this.role = 'coowner';
    //             this.role = this.userData['data']['role']
    //         }
    //     } else {
    //         if (this.userData && this.userData['data'] && this.userData['data']['role'] == 't2') {
    //             this.role = 't2';
    //         } else {
    //             this.role = 'coowner';
    //         }

    //     }
    // }

    async getUserDetails() {
        var data = window.localStorage.getItem('userData');
        this.userData = JSON.parse(data);
        // console.log("is Auth");
        //console.log(this.userData['data']['manage_user_type']);
        //console.log("auth data",this.userData);
        //console.log("brefore role "+this.role+"userRole "+this.userRole);
        if (this.userData['data']) {
            if (this.userData['data']['user_type']) {
                this.role = (this.userData['data']['user_type']).toLowerCase();
            } else if (this.userData['data']['manage_user_type']) {
                this.role = (this.userData['data']['manage_user_type']).toLowerCase();
            }
            if (this.userData['data']['role']) {
                this.userRole = this.userData['data']['role'];
            }
        } else {
            this.role = '';
            this.userRole = '';
        }

        console.log("role " + this.role + "userRole " + this.userRole);
    }

    restrictedSection(url) {
        console.log();
        if (this.restirctedArray.indexOf(url) >= 0) {
            return true;
        }
        else {
            return false;
        }
    }

}

