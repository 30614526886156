import { Component, OnInit } from '@angular/core';
import { ShareService } from '../../services/ShareService';
import{ appCommonMethods } from '../../services/appCommonMethods';
import { ALL_MESSAGE } from '../../services/config';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-no-internet-connection',
  templateUrl: './no-internet-connection.page.html',
  styleUrls: ['./no-internet-connection.page.scss'],
})
export class NoInternetConnectionPage implements OnInit {

  constructor(
    public shareService: ShareService,
    public appCom: appCommonMethods,
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }

  reloadPage(){
    let internet = this.shareService.getshareData("netConnection");
    if(internet){
      this.modalCtrl.dismiss();
    }
    else{
      this.appCom.showToast(ALL_MESSAGE.ERROR_MESSAGE.NO_INTERNET, 1500);
    }
  }

}
